import React from "react"
import "../assets/stylesheets/components/404.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/btn"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found-page">
      <h1>404 | PAGINA NON TROVATA</h1>
      <p>Il percorso che hai digitato non esiste, torna alla home.</p>
      <Button to="/" className="btn btn-primary btn-lg">
          Torna alla home
      </Button>
    </div>
  </Layout>
)

export default NotFoundPage
